<template>
  <div class="download-wrap">
    <div class="bg-wrap"></div>
    <div class="content-wrap">
      <img class="logo-name" src="../../assets/img/logo-2.png" alt="" />
      <img class="logo" src="../../assets/img/logo.png" alt="" />
      <div class="button-wrap">
        <div class="btn" @click="showPop" v-if="isWeixin">Android下载</div>
        <a class="btn" v-else :href="config.apk">Android下载</a>
        <div class="label">当前版本：{{ config.version }}</div>
        <div class="qrcode" v-if="config.apk">
          <VueQRCodeComponent :text="config.apk" :size="100" />
        </div>
      </div>
    </div>
    <van-popup v-model="pop" class="pop-wrap" @click="pop = false">
      <div class="arrow"></div>
      <div class="wrap">
        <div>1.点击屏幕右上角【...】</div>
        <div>2.选择【在浏览器中打开】</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import VueQRCodeComponent from "vue-qrcode-component";
import { mapState } from "vuex";
export default {
  data() {
    return {
      pop: false,
      qrColor: { dark: "#000000ff", light: "#ffffffff" },
      config: {},
    };
  },
  components: {
    VueQRCodeComponent,
  },
  computed: {
    ...mapState(["isWeixin"]),
  },
  created() {
    if (this.isWeixin) {
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: "小竹猫Android下载", // 分享标题
          desc: "小竹猫", // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: "", // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    }
    this.fetch();
  },
  methods: {
    showPop() {
      this.pop = true;
    },
    async fetch() {
      let ret = await this.$http.get(`/last-setting`);
      this.config = ret;
      this.config.apk = ret.apk.replace("http:", "https:");
    },
  },
};
</script>
<style lang="scss" scoped>
.download-wrap {
  flex: 1;
  overflow: hidden;
  background: url(../../assets/img/bg-m.jpeg) 0 0 no-repeat;
  background-size: cover;
  position: relative;
  .content-wrap {
    .logo {
      display: block;
      width: r(140);
      height: r(140);
      border-radius: r(10);
      margin: 0 auto;
    }
    .logo-name {
      display: block;
      height: r(140);
      border-radius: r(10);
      margin: 0 auto;
    }
    .button-wrap {
      margin-top: r(200);
      font-size: r(20);
      color: #fff;
      .btn {
        display: block;
        padding: r(20) r(40);
        text-align: center;
        background: #70e16f;
        border-radius: r(6);
        color: #fff;
        line-height: r(40);
        font-size: r(30);
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: inline-block;
          width: r(40);
          height: r(40);
          margin-right: r(6);
          background: url(../../assets/img/android.png) 0 0 no-repeat;
          background-size: contain;
        }
      }
      .label {
        margin-top: r(10);
        font-size: r(20);
        text-align: center;
      }
      .qrcode {
        margin-top: r(20);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          padding: r(8);
          background: #fff;
          border-radius: r(8);
        }
      }
    }

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .pop-wrap {
    background: transparent;
    height: 100%;
    width: 100%;
    .arrow {
      position: absolute;
      right: r(40);
      top: r(40);
      width: r(208);
      height: r(230);
      background: url(../../assets/img/arrow.png) 0 0 no-repeat;
      background-size: contain;
    }
  }
  .wrap {
    position: absolute;
    top: r(270);
    color: #fff;
    right: r(248);
    font-size: r(30);
    line-height: r(50);
  }
}
</style>